<template>
  <v-menu v-model="openMenu" offset-y left :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <icon-button v-bind="attrs" v-on="on">
        <v-icon>
          mdi-dots-horizontal
        </v-icon>
      </icon-button>
    </template>

    <v-list>
      <v-list-item v-if="media.isMobile && canUpdate" :to="clientRoute">
        <v-list-item-title>
          <v-icon right>mdi-plus</v-icon>
          {{ $t('button.add_client') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item @click="exportClients">
        <v-list-item-title>
          <v-icon right>mdi-file-download</v-icon>
          {{ $t('button.export') }}
        </v-list-item-title>
      </v-list-item>

      <file-input
        v-if="media.isMobile && canUpdate"
        v-slot="{ on, attrs }"
        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        @input="importClients"
      >
        <v-list-item :disabled="isProcessing" v-bind="attrs" v-on="on" @click.prevent>
          <v-list-item-title>
            <v-icon right>mdi-file-upload</v-icon>
            {{ $t('button.import_clients') }}
          </v-list-item-title>
        </v-list-item>
      </file-input>

      <v-list-item v-if="media.isMobile && canUpdate" :disabled="isProcessing">
        <file-input
          v-if="canUpdate"
          v-slot="{ on, attrs }"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          @input="importAddresses"
        >
          <v-list-item-title v-bind="attrs" v-on="on" @click.prevent>
            <v-icon right>mdi-file-upload</v-icon>
            {{ $t('button.import_addresses') }}
          </v-list-item-title>
        </file-input>
        <div class="px-1" @click.stop.prevent>
          <v-tooltip top max-width="250">
            <template #activator="{ on: textOn, attrs: textAttrs }">
              <v-icon color="gray" v-bind="textAttrs" v-on="textOn">mdi-information</v-icon>
            </template>

            <span>{{ $t('clients.import_addresses_tooltip') }}</span>
          </v-tooltip>
        </div>
      </v-list-item>

      <v-list-item v-if="canUpdate" @click="unarchiveClients">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-up</v-icon>
          {{ $t('button.unarchive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="canUpdate" @click="archiveClients">
        <v-list-item-title>
          <v-icon right>mdi-archive-arrow-down</v-icon>
          {{ $t('button.archive') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="!isShortTenantsType" @click="resentEmail">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-multiple-marked</v-icon>
          {{ $t('button.reset_password_email') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="!isShortTenantsType" @click="sendEmail">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-multiple-marked</v-icon>
          {{ $t('button.send_password_email') }}
        </v-list-item-title>
      </v-list-item>

      <v-list-item v-if="media.isMobile" @click="selectAll">
        <v-list-item-title>
          <v-icon right>mdi-checkbox-marked</v-icon>
          {{ $t('button.select_all') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
// Constants
import { CLIENTS_CREATE } from '@/constants/routes';
import * as typesClient from '@/constants/typesClients';

// Components
import IconButton from '@/components/IconButton.vue';
import FileInput from '@/components/FileInput.vue';

export default {
  name: 'ClientsMenu',

  components: { IconButton, FileInput },

  inject: ['media'],

  props: {
    typeClient: { type: String, required: true },
    page: { type: Number, default: 1 },
    isProcessing: { type: Boolean, default: false },
    canUpdate: { type: Boolean, default: false },
  },

  data() {
    return {
      openMenu: false,
    };
  },

  computed: {
    clientRoute() {
      return { name: CLIENTS_CREATE, query: { prevPage: this.page } };
    },
    isShortTenantsType() {
      return this.typeClient === typesClient.SHORT_TENANTS;
    },
  },

  methods: {
    exportClients() {
      this.$emit('export-clients');
      this.closeMenu();
    },

    importClients(file) {
      this.$emit('import-clients', file);
      this.closeMenu();
    },

    importAddresses(file) {
      this.$emit('import-addresses', file);
      this.closeMenu();
    },

    unarchiveClients() {
      this.$emit('unarchive-clients');
      this.closeMenu();
    },

    archiveClients() {
      this.$emit('archive-clients');
      this.closeMenu();
    },

    resentEmail() {
      this.$emit('resent-email');
      this.closeMenu();
    },

    sendEmail() {
      this.$emit('send-email');
      this.closeMenu();
    },

    selectAll() {
      this.$emit('select-all');
      this.closeMenu();
    },

    closeMenu() {
      this.openMenu = false;
    },
  },
};
</script>
